@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");

body {
  /* background-image: url("https://media.giphy.com/media/WNThAzj7pg9teHE6JU/giphy.gif"); */
  /* background-size: cover; */
  /* font-family: "VT323", monospace; */
  font-size: 1.3em;
  line-height: 1.3em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
