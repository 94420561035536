.App {
  padding: 10px 80px;
  max-width: 800px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
  margin: auto;
  opacity: 0.925;
  background-color: #fff;
  margin-top: 3%;
}

.video {
  aspect-ratio: 16 / 9;
  height: 100%;
  width: 100%;
}

.navbar {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
}
